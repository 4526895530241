<div class="container-fluid shadow-bot">
  <div class="justify-content-start">
    <img src="https://cdn.isifid.com/clients/CACEE9528800D4243DD/Logo ca-Centre_Est-2-RVB.jpg"
         alt="Crédit Agricole Centre-est" class="logo" width="250" />
  </div>

</div>
<div class="container-fluid">
  <div class="m-0 mx-auto py-4 px-5 ms-xl-5 row align-items-center">
    <div class="col"><span class="title-intro">Parrainage - Crédit Agricole Centre-est</span></div>
  </div>
</div>
<div class="container-fluid text-center">
  <div class="row">
    <div class="col-md parrain px-3 py-5">
      <div class="title-par py-1">Pour vous parrain, bénéficiez jusqu'à&nbsp;:</div>
      <div class="d-flex justify-content-center py-1">
        <div class="align-self-center prix">240€</div>
        <div class="complement align-self-center pl-10" align="left">
          <div>de bons</div>
          <div>d'achats&nbsp;!<sup>(1)</sup></div>
        </div>
      </div>
      <div class="complement py-1">80€<sup>(1)</sup> par parrainage abouti dans la limite&nbsp;de&nbsp;3.
      </div>
      <div class="d-flex justify-content-center py-3 w-100">
        <a class="btn-blanc vert"
           (click)="referralCreation()">Je parraine mes
          proches</a>
      </div>
    </div>
    <div class="col-md filleul px-3 py-5">
      <div class="title-par py-1">Pour votre filleul, jusqu'à&nbsp;:</div>
      <div class="d-flex justify-content-center py-1">
        <div class="align-self-center prix">130€</div>
        <div class="complement align-self-center pl-10" align="left">
          <div>&nbsp;</div>
          <div>offerts<sup>(2)</sup></div>
        </div>
      </div>
      <div class="complement py-1">grâce à l'offre de bienvenue</div>
      <div class="d-flex justify-content-center py-3 w-100">
        <a class="btn-blanc bleu"
           (click)="referralCreation()">Je parraine mes
          proches</a>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid text-center">
  <div class="row">
    <div class="col d-flex justify-content-center">
      <div class="title-1 pt-40 pb-40 px-2">Une offre de bienvenue pour vos filleuls&nbsp;!</div>
    </div>
  </div>
</div>
<div class="container-fluid pb-5">
  <div class="row justify-content-center gx-3">
    <div class="col-sm-4 carroussel">
      <div class=""><img
        src="https://cdn.isifid.com/clients/CACEE9528800D4243DD/CARROUSEL-1.jpg"
        alt="" class="img-gallerie" /></div>
      <div class="texte-desc  pt-20"><b>Jusqu'à 80€ offerts<sup>(2)</sup></b> pour votre filleul à
        l'ouverture d'un 1<sup>er</sup> compte bancaire</div>
    </div>
    <div class="col-sm-4 carroussel top">
      <div class=""><img
        src="https://cdn.isifid.com/clients/CACEE9528800D4243DD/CARROUSEL-2.jpg"
        alt="" class="img-gallerie" /></div>
      <div class="texte-desc  pt-20"><b>50€ versés<sup>(2)</sup></b> sur son Livret Engagé Sociétaire lors de son ouverture</div>
    </div>
    <div class="col-sm-4 carroussel top">
      <div class=""><img
        src="https://cdn.isifid.com/clients/CACEE9528800D4243DD/CARROUSEL-3.jpg"
        alt="" class="img-gallerie" /></div>
      <div class="texte-desc  pt-20">Une gestion de ses comptes <b>100% en ligne</b> et un <b>accès direct à
        un conseiller gr&acirc;ce à 261 agences</b> sur notre territoire</div>
    </div>
  </div>
</div>
<div class="container-fluid text-center">
  <div class="row">
    <div class="col d-flex justify-content-center">
      <div class="title-1 pb-60 px-2">
        <a (click)="referralCreation()"
           class="btn-vert">Je parraine mes proches</a>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid pt-40 pb-40 bg-custom">
  <div class="row">
    <div class="col d-flex justify-content-center">
      <div class="title-1 pt-30 pb-30 px-2">Comment ça marche&nbsp;?</div>
    </div>
  </div>
  <div class="row justify-content-center pt-10 pb-50">
    <div class="col-lg-4 px-5 pt-3">
      <div class="d-flex justify-content-center">
        <div class="min-w-54">
          <div class="text-center align-self-start puce-etape">1</div>
        </div>
        <div class="w-100">
          <div class="align-self-center pl-10 pt-10" align="left">
            <div class="title-etape">Je partage mon code parrain</div>
            <div class="texte-etape pt-10">Saisissez les <b>coordonnées de vos amis</b> et nous leur
              enverrons votre <b>code parrain</b> par email.</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 px-5 pt-3">
      <div class="d-flex justify-content-center">
        <div class="min-w-54">
          <div class="text-center align-self-start puce-etape">2</div>
        </div>
        <div>
          <div class="align-self-center pl-10 pt-10" align="left">
            <div class="title-etape">Mon filleul ouvre son compte</div>
            <div class="texte-etape pt-10">Votre filleul ouvre son compte dans <b>l'agence</b> Crédit
              Agricole Centre-est de son choix <b>muni de votre code Parrain.</b></div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 px-5 pt-3">
      <div class="d-flex justify-content-center">
        <div class="min-w-54">
          <div class="text-center align-self-start puce-etape">3</div>
        </div>
        <div>
          <div class="align-self-center pl-10 pt-10" align="left">
            <div class="title-etape">J'obtiens ma récompense</div>
            <div class="texte-etape pt-10">Dès la <b>validation de l'ouverture de compte</b> de votre filleul,
              <b>vous recevrez par e-mail votre récompense</b> de 80€ en bons d'achat.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid text-center pb-80">
  <div class="row">
    <div class="col justify-content-center">
      <div class="title-1 pt-40 pb-40 px-2">Alors, conquis&nbsp;?</div>
      <a (click)="referralCreation()" class="btn-vert">Je parraine mes proches</a>
    </div>
  </div>
</div>
<div class="container-fluid px-5 pb-5">
  <div class="mentions pt-5">
    Document à caractère publicitaire.
    <br><br>
    (1) Offre valable du 12/03/2024 jusqu’au 31/05/2025, réservée aux majeurs, limitée à 3 filleuls par parrain, sous réserve d’une première ouverture d’un compte chèque par le filleul avant le 31/05/2025, versement des cadeaux dans un délais d’un mois après ouverture du compte. Réservé aux clients particuliers. Sous réserve d’acceptation de votre dossier. Bons cadeaux sous forme de codes de 10 à 80€ pour le parrain en cas de parrainages multiples, selon les enseignes partenaires. Un code attribuable par enseigne. Durée de validité de la récompense : 6 mois à compter de son attribution. Une fois l’enseigne choisie, la durée de validité de chaque code reçu pour l’enseigne choisie est d’un mois à compter de sa livraison par e-mail.
    <br><br>
    (2)"Jusqu’à 130€ offerts" : Offres soumises à conditions, sous réserve d’acceptation par la banque, valables du
    12/03/2024 au 31/05/2025. Offres réservées aux nouveaux clients majeurs devenant sociétaires au plus tard
    dans les 30 jours suivant la date d’entrée en relation. Pour bénéficier de ces offres, la personne majeure ne doit
    pas détenir au préalable d’autres contrats à son nom au Crédit Agricole Centre-est. Offres non cumulables avec
    les autres offres Crédit Agricole Centre-Est en cours.
    <br><br>
    “Jusqu’à 80€ offerts” : Offre valable pour toute souscription d'une formule Ma Banque au Quotidien (MBQ)
    Essentiel, Premium ou Prestige. Le premier remboursement s’effectuera au plus tard le 10 du mois suivant la
    date d’entrée en relation et la souscription concomitante de la formule MBQ concernée. Pour la formule
    Essentiel, un remboursement de 10 € s'applique chaque mois pendant 3 mois, soit un montant total de 30€.
    Pour la formule Premium, un remboursement de 10 € s'applique chaque mois pendant 6 mois, soit un montant
    total de 60€. Pour la formule Prestige, un remboursement de 10 € s'applique chaque mois pendant 8 mois, soit
    un montant total de 80€. Renseignez-vous auprès de votre conseiller pour connaître les conditions de cette offre
    et limites applicables. Vous disposez d’un délai légal de rétractation en cas de démarchage et/ou de vente à
    distance.
    <br><br>
    “50€ versés” : valable pour toute souscription d’un Livret Engagé Sociétaire. Une prime de 50 € sera versée
    pour l’ouverture d’un Livret Engagé Sociétaire (LES) au nom du nouveau client devenu sociétaire, résident en
    France. Le versement des 50€ sur le Livret Engagé Sociétaire sera réalisé au plus tard le 10 du mois suivant la
    souscription réalisée dans les 30 jours qui suivent l’ouverture du contrat. Renseignez-vous auprès de votre
    conseiller pour connaître les conditions de cette offre et limites applicables. Vous disposez d’un délai légal de
    rétractation en cas de démarchage et/ou de vente à distance.
    <br><br>
    En cas de clôture du compte du client, soit à l’initiative du client lui-même, soit à l’initiative du Crédit Agricole
    Centre-est en cas de comportement gravement répréhensible du client, au cours des 12 mois suivant son
    ouverture effective, le Crédit Agricole Centre-est se réserve le droit de prélever sur ledit compte le montant
    correspondant à la prime ou aux primes de bienvenue dont il a bénéficié dans le cadre de cette offre
    commerciale. Le Crédit Agricole Centre-est se réserve le droit de modifier, suspendre ou mettre fin à tout
    moment à l’offre.
    <br><br>
    Renseignez-vous auprès de votre conseiller pour connaître les conditions de cette offre et limites applicables.<br>
    Vous disposez d’un délai légal de rétractation en cas de démarchage et/ou de vente à distance.
    <br><br>
    Les données à caractère personnel recueillies par votre Caisse Régionale du Crédit Agricole Centre-est en
    qualité de responsable du traitement dans le cadre du présent formulaire seront utilisées pour la finalité
    suivante : Offre de parrainage. Vous pouvez à tout moment dans les conditions prévues par la loi, accéder à vos
    données personnelles, vous opposer pour motif légitime à leur traitement, les faire rectifier, demander leur
    effacement, la limitation de leur traitement, leur portabilité, ou communiquer des instructions sur leur sort en
    cas de décès. Vous disposez du droit de vous opposer, sans frais, à l’utilisation de vos données personnelles à
    des fins de prospection en écrivant au Délégué des Données : Caisse Régionale de Crédit Agricole Mutuel
    Centre-est, 1 rue Pierre de Truchis de Lays, 69410 Champagne-au-Mont d’Or – <a
    href="mailto:dpo@ca-centrest.fr" class="lien">dpo&#64;ca-centrest.fr</a> – par courrier
    à l’adresse Délégué à la Protection des Données – Crédit Agricole Centre-est – 1, rue Pierre de Truchis de Lays –
    69410 CHAMPAGNE AU MONT D’OR. Frais de timbre remboursés sur simple demande de votre part – En cas de
    contestation, vous pouvez former une réclamation auprès de la CNIL dont le site internet est accessible à
    l’adresse suivante <a href="https://www.cnil.fr" target="_blank" class="lien">https://www.cnil.fr</a> et le siège est situé 3 Place de Fontenoy, 75007 Paris Vous pouvez enfin
    consulter la politique de protection des données du Crédit Agricole Mutuel Centre-est en agence ou en
    consultant la page internet : <a
    href="https://www.credit-agricole.fr/ca-centrest/particulier/informations/politique-de-confidentialite.html"
    target="_blank"
    class="lien">https://www.credit-agricole.fr/ca-centrest/particulier/informations/politique-de-confidentialite.html</a>
    CAISSE RÉGIONALE DE CRÉDIT AGRICOLE MUTUEL CENTRE-EST - Société coopérative à
    capital variable, agréée en tant qu’établissement de crédit. Siège social situé 1, rue Pierre de Truchis de Lays -
    69410 CHAMPAGNE AU MONT D'OR – 399 973 825 RCS Lyon - Société de courtage d’assurance immatriculée au
    Registre Unique des Intermédiaires en Assurance, Banque et Finance sous le n° 07 023 262 (<a href="https://www.orias.fr"
                                                                                                  target="_blank" class="lien">www.orias.fr</a>).
    <br><br>
    <a href="https://www.credit-agricole.fr/ca-centrest/particulier/informations/politique-de-confidentialite.html"
       target="_blank" class="lien">Politique relative aux cookies</a>
    <br><br>
    CAISSE RÉGIONALE DE CRÉDIT AGRICOLE MUTUEL CENTRE-EST, Société coopérative à capital variable, agréée
    en tant qu'établissement de crédit - Siège social 1, rue Pierre de Truchis de Lays 69410 Champagne au Mont
    d'Or - 399 973 825 RCS Lyon - Société de courtage d'assurance immatriculée au Registre Unique des
    Intermédiaires en Assurance Banque et Finance sous le numéro 07 023 262.
  </div>
</div>
<hr class="separateur">
<div class="container-fluid text-center py-2">
  <img src="https://cdn.isifid.com/clients/CACEE9528800D4243DD/Logo ca-Centre_Est-2-RVB.jpg"
       alt="Crédit Agricole Centre-est" class="logo" width="250" />
</div>
