import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {sponsorshipRouting} from './sponsor.routes';
import {InviteComponent} from './invite/invite.component';
import {CreateComponent} from './account/create/create.component';
import {SharedModule} from '../../shared/shared.module';
import {ExplanationsComponent} from './home/common/explanations.component';
import {Routes, ROUTES} from '@angular/router';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatExpansionModule} from '@angular/material/expansion';
import {HistoryComponent} from './account/history/history.component';
import {SharedBannerComponent} from './account/shared-banner/shared-banner.component';
import {InformationsComponent} from './account/informations/informations.component';
import {LoginComponent} from './account/login/login.component';
import {ValidateComponent} from './account/validate/validate.component';
import {authGuardFn, CacheService, SponsorshipSettings, trackGuardFn} from '@isifid/core';
import { QRCodeComponent } from 'angularx-qrcode';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatOptionModule} from '@angular/material/core';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import { PlatformModule } from '@angular/cdk/platform';
import {CustomCodeComponent} from './account/custom-code/custom-code.component';
import {UnsubscribeComponent} from './account/unsubscribe/unsubscribe.component';
import {CEHDF0059038FD37B4AComponent} from './home/CEHDF0059038FD37B4A/CEHDF0059038FD37B4A.component';
import {CACEE9528800D4243DDComponent} from './home/CACEE9528800D4243DD/CACEE9528800D4243DD.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        sponsorshipRouting,
        SharedModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatOptionModule,
        MatSelectModule,
        MatExpansionModule,
        QRCodeComponent,
        ClipboardModule,
        PlatformModule,
    ],
    declarations: [
        CreateComponent,
        CustomCodeComponent,
        InviteComponent,
        ValidateComponent,
        ExplanationsComponent,
        HistoryComponent,
        SharedBannerComponent,
        InformationsComponent,
        LoginComponent,
        UnsubscribeComponent,
        CEHDF0059038FD37B4AComponent,
        CACEE9528800D4243DDComponent
    ],
    exports: [
        CreateComponent,
        InviteComponent,
        HistoryComponent,
        SharedBannerComponent
    ],
    providers: [
        {
            provide: ROUTES,
            useFactory: configExplanationsPage,
            deps: [CacheService],
            multi: true
        }
    ]
})

export class SponsorModule {
}

// Allow handling custom routes for dedicated homepage
export function configExplanationsPage(cacheService: CacheService) {
    let routes: Routes;
    const settings: SponsorshipSettings = cacheService.getContent('settings');
    if (settings?.configuration?.['customExplanationsPage']) {
        routes = settings?.templateHash === 'CACEE9528800D4243DD' ?
            [
                {
                    path: 'parrain/accueil',
                    canActivate: [trackGuardFn, authGuardFn],
                    component: CACEE9528800D4243DDComponent
                }
            ] :
            [
                {
                    path: 'parrain/accueil',
                    canActivate: [trackGuardFn, authGuardFn],
                    component: CEHDF0059038FD37B4AComponent
                }
            ];
    } else {
        routes = [
            {
                path: 'parrain/accueil',
                canActivate: [trackGuardFn, authGuardFn],
                component: ExplanationsComponent
            }
        ];
    }
    return routes;
}
