import {ChangeDetectorRef, Component} from '@angular/core';
import {SponsorshipService} from '../../../../shared/services/sponsorship.service';
import {SponsorService} from '../../../../shared/services/sponsor.service';
import {ShareService} from '../../../../shared/services/share.service';
import {forkJoin} from 'rxjs';
import {Router} from '@angular/router';
import {Client, Consumer, MsClientsService, MsConsumersService, TrackingService} from '@isifid/core';
import {animate, style, transition, trigger} from '@angular/animations';
import { Platform } from '@angular/cdk/platform';
import {TrackingServiceSponsorship} from '../../../../shared/services/tracking-service-sponsorship.service';

@Component({
    selector: 'app-sponsor-shared-banner',
    templateUrl: './shared-banner.component.html',
    styleUrls: ['./shared-banner.component.scss'],
    animations: [
        trigger('inOutAnimation', [
            transition(':enter', [
                style({ height: 0, opacity: 0 }),
                animate('0.4s ease-out', style({ height: '*', opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 0 }),
                animate('0.4s ease-in', style({ height: 0, opacity: 0 }))
            ])
        ])
    ],
    standalone: false
})
export class SharedBannerComponent {
    consumer: Consumer;
    copy: boolean = false;
    copyContent = 'Copier';
    copyContentDesktop = 'Copier code parrain';
    isCollapsed = true;
    purl: string = null;
    rewards: Array<any>;
    showModal = false;
    urlToShare: string;
    qrdata: string;

    constructor(
        public readonly router: Router,
        public readonly sponsorService: SponsorService,
        public readonly sponsorshipService: SponsorshipService,
        public readonly trackingService: TrackingService,
        public readonly trackingServiceSponsorship: TrackingServiceSponsorship,
        private readonly msConsumersService: MsConsumersService,
        private readonly msClientsService: MsClientsService,
        private readonly shareService: ShareService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly platForm: Platform
    ) {
        this.urlToShare = `${window.location.origin}/c/${this.sponsorService.sponsorshipUser.codeCustomised || this.sponsorService.sponsorshipUser.code}`;
        this.qrdata = `${window.location.origin}/c/${this.sponsorService.sponsorshipUser.codeCustomised || this.sponsorService.sponsorshipUser.code}?utm_source=account&utm_medium=qr-code`;
        forkJoin([
            this.msConsumersService.getConsumer(this.sponsorService.sponsorshipUser.consumerId),
            this.msConsumersService.getRewards(this.sponsorService.sponsorshipUser.consumerId)
        ]).subscribe({
            next: ([consumer, rewards]) => {
                this.consumer = consumer;
                this.rewards = rewards;
                this.generatePUrl();
                this.changeDetectorRef.markForCheck();
            }
        });
    }

    isShareApiAvailable(): boolean {
        return (this.shareService.canShare() && this.platForm.ANDROID || this.platForm.IOS) || !this.platForm.ANDROID || !this.platForm.IOS;
    }

    shareMobile(): void {
        if (this.isShareApiAvailable()) {
            this.shareService.share({
                title: '',
                text: this.sponsorshipService.getContent().sponsoredSocialText,
                url: this.urlToShare
            }).subscribe();
        }
    }

    generatePUrl(): void {
        this.purl = '';
        // Only generate pURL for sponsors
        if (this.sponsorshipService.mode !== 'sponsor') return;

        this.msClientsService.getClient(this.consumer.clientId).subscribe({
            next: (client: Client) => {
                if (!client.domain.startsWith('https://')) client.domain = `https://${client.domain}`;
                this.purl = `${client.domain}/auth?token=${this.sponsorService.consumer?.purlToken}`;
                this.changeDetectorRef.markForCheck();
            }
        });
    }

    inviteByMail(): void {
        this.router.navigate(['/parrain/inviter'], {fragment: 'email'}).then(() => this.isCollapsed = true);
    }

    copyCode(): void {
        this.copy = true;
        setTimeout(() => this.copy = false, 10000);
    }
}
