<div class="container-fluid pb-2 mb-5 bg-custom text-white more">
    <!--  mobile --------------------------------------------------------------------------------------------------- -->
    <div class="d-block d-md-none">

        <div class="row pt-3 d-flex flex-row justify-content-end">
            <div class="col col-sm-4 mx-sm-auto">
                <p class="my-0 fw-bold">Parrain : </p>
                @if (consumer) {<p class="my-0">{{consumer.lastName}} {{consumer.firstName}}</p>}
                @if (sponsorshipService.mode === 'sponsor') {
                    <p class="my-0 text-underline" data-cy="information"
                       routerLink="/parrain/compte/informations">
                        Modifier mes infos
                    </p>
                }
                @if (!sponsorService.sponsorshipUser?.codeCustomised) {
                    <p class="my-0 text-underline"
                       routerLink="/parrain/compte/personnalisation">
                        Modifier mon code parrain
                    </p>
                }
            </div>
            <div class="col col-sm-4 mx-sm-auto">
                @if (!sponsorshipService.rewardWithCash) {
                    <p class="my-0 fw-bold">Total récompense :</p>
                    @if (consumer) {<p class="my-0">{{consumer.balance}} € de bons d’achat</p>}
                }
            </div>
        </div>

        <div class="row mb-n3">
            <div class="col text-center zIndex1">
                <em aria-hidden="true" class="fas fa-circle fa-2x round-border"></em>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-11 col-sm-9 bg-gold mx-auto py-3">
                <div class="row">
                    <div class="col my-auto mx-auto text-center">

                        <p class="my-0">Code Parrain :</p>
                        <p class="mt-0 mb-3 h5 fw-bolder">
                            {{sponsorService.sponsorshipUser?.codeCustomised || sponsorService.sponsorshipUser?.code}}
                        </p>

                        @if (isShareApiAvailable()) {
                            <div (click)="trackingService.trackEvent('share', 'native','', '',
                                          trackingServiceSponsorship.getClientTrackerId());shareMobile()"
                                 class="row btn btn-qr w-100 m-1">
                                <em aria-hidden="true" class="fas fa-share-alt ms-1"></em> Partager
                            </div>
                        }
                        <div (cdkCopyToClipboardCopied)="trackingService.trackEvent('share', 'copy code','', '',
                             trackingServiceSponsorship.getClientTrackerId()); copyContent='Copié !'"
                             [cdkCopyToClipboard]="urlToShare"
                             class="row btn btn-qr w-100 text-small m-1">
                            <em aria-hidden="true" class="fas fa-copy"></em> {{copyContent}}
                        </div>
                    </div>
                    <div (click)="showModal = !showModal" (keydown)="showModal = !showModal"
                         class="col pt-3 mx-auto text-center">
                        <qrcode data-cy="qr-code" [qrdata]="qrdata" errorCorrectionLevel="L" size="100" />
                        <p>+ ZOOM</p>
                        <p>Ce QR code permet à votre filleul de prendre RDV avec votre conseiller</p>
                    </div>
                </div>
            </div>
        </div>

        @if (!isCollapsed) {

            <div [@inOutAnimation]="true" class="moreInfo">
                <div class="row my-1">
                    <div class="col col-sm-8 mx-auto text-center">
                        <button (click)="isCollapsed = true"
                                [fragment]="router.url.startsWith('/parrain/inviter') ? 'email': ''"
                                [ngClass]="router.url.startsWith('/parrain/inviter') ? 'btn-select text-white' : 'btn-gold'"
                                class="btn py-2"
                                routerLink="/parrain/inviter">Inscrire des filleuls
                        </button>
                    </div>
                </div>
                @if (consumer && purl && (rewards?.length > 0 || consumer.balance > 0)) {
                    <div class="row my-1">
                        <div class="col col-sm-8 mx-auto text-center">
                            @if (sponsorshipService.mode === 'sponsor' && !sponsorshipService.rewardWithCash) {
                                <a [href]="purl" rel="noopener" target="_blank">
                                    <button class="btn btn-gold py-2">Accéder à mes récompenses</button>
                                </a>
                            }
                        </div>
                    </div>
                }
                <div class="row my-1">
                    <div class="col col-sm-8 mx-auto text-center">
                        <button [ngClass]="router.url === '/parrain/compte/historique' ? 'btn-select text-white':'btn-gold'"
                                class="btn py-2"
                                routerLink="/parrain/compte/historique">Suivi des parrainages
                        </button>
                    </div>
                </div>
            </div>
        }
    </div>

    <!-- Desktop --------------------------------------------------------------------------------------------------- -->
    <div class="d-none d-md-block p-0 m-0">
        @if (!isCollapsed) {
            <div class="container mx-auto px-0 container-fix">
                <div class="row pt-3 mb-n4">
                    <div class="col px-0 mx-0"></div>
                    <div class="col mx-0 px-0 px-5 mb-n5 mt-0 text-center zIndex1 customCenter">
                        <div class="px-5 mx-lg-5">
                            <em aria-hidden="true" class="fas fa-circle fa-2x round-border"></em>
                        </div>
                    </div>
                </div>
            </div>
        }

        <div class="container mx-auto py-4 px-0 container-fix">
            <div class="row pt-3">

                <div class="col mx-0 p-0 ps-1 text-start">
                    <div class="col ps-lg-3 ps-xl-5 mx-auto">
                        <p class="my-0">
                            <span class="fw-bold h4">Parrain : </span>
                            @if (consumer) {
                                <span class="h5 text-break">{{consumer.lastName}} {{consumer.firstName}}</span>
                            }
                        </p>
                        <div class="my-0">
                            @if (sponsorshipService.mode === 'sponsor') {
                                <a class="text-white text-underline h5" data-cy="informationLaptop"
                                   routerLink="/parrain/compte/informations">Modifier mes informations personnelles
                                </a>
                            }
                            @if (!sponsorService.sponsorshipUser?.codeCustomised) {
                                <p class="mt-1 text-white text-underline h5 clickable"
                                   routerLink="/parrain/compte/personnalisation">Modifier mon code parrain
                                </p>
                            }
                        </div>
                    </div>

                    @if (!isCollapsed) {
                        <div [@inOutAnimation]="true" class="moreInfo">
                            <div class="col mx-auto ps-lg-3 ps-xl-5 pt-3">
                                @if (!sponsorshipService.rewardWithCash) {
                                    <p class="mt-2 mb-0">
                                        <span class="fw-bold h4">Total récompense :</span><br>
                                    </p>
                                    @if (consumer) {<p class="mt-0 mb-3 h4">{{consumer.balance}} €</p>}
                                }

                                <div class="row mt-5 mb-1">
                                    <div class="col">
                                        <button [fragment]="router.url.startsWith('/parrain/inviter') ? 'email': ''"
                                                [ngClass]="router.url.startsWith('/parrain/inviter') ?
                                                'btn-select text-white' : 'btn-gold'"
                                                class="btn py-2 h5"
                                                routerLink="/parrain/inviter">Inscrire des filleuls
                                        </button>
                                    </div>
                                </div>
                                @if (consumer && purl && (rewards?.length > 0 || consumer.balance > 0)) {
                                    <div class="row my-1">
                                        <div class="col">
                                            @if (sponsorshipService.mode === 'sponsor' && !sponsorshipService.rewardWithCash) {
                                                <a [href]="purl" rel="noopener" target="_blank">
                                                    <button class="btn btn-gold py-2 h5">Accéder à mes récompenses</button>
                                                </a>
                                            }
                                        </div>
                                    </div>
                                }
                                <div class="row my-1">
                                    <div class="col">
                                        <button [ngClass]="router.url === '/parrain/compte' ?
                                                'btn-select text-white' : 'btn-gold'"
                                                class="btn py-2 h5"
                                                data-cy="history"
                                                routerLink="/parrain/compte/historique">Suivi des parrainages
                                        </button>
                                    </div>
                                </div>
                            </div>
                            @if (sponsorshipService.getSettings().socialShareEnabled) {
                                <div class="col mx-auto ps-lg-3 ps-xl-5">
                                    <div class="my-3 d-flex flex-row justify-content-start">
                                        @if (isShareApiAvailable()) {
                                            <button (click)="trackingService.trackEvent('share', 'native','', '',
                                                    trackingServiceSponsorship.getClientTrackerId());shareMobile()"
                                                    class="btn btn-social py-3 px-4 ms-2 zoom">
                                                <em aria-hidden="true" class="fas fa-share-alt fa-2x"></em>
                                            </button>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>

                @if (isCollapsed) {
                    <div class="col ms-3 px-0 px-xl-5">
                        <div (click)="copyCode()" [cdkCopyToClipboard]="urlToShare"
                             class="bg-gold text-center clickable py-2 mx-1 mx-lg-4">
                            <div class="row">
                                <div class="col-8 py-2 ps-5">
                                    @if (!copy) {<p class="m-0 h5">Code parrain</p>}
                                    @else {<p class="m-0 h5">Code copié</p>}
                                    <h6 class="m-0 h5 fw-bold">
                                        {{sponsorService.sponsorshipUser?.codeCustomised || sponsorService.sponsorshipUser?.code}}
                                    </h6>
                                </div>
                                <div class="col-4 my-auto">
                                    <em aria-hidden="true" class="fas fa-copy fa-2x me-5"></em>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                @if (isCollapsed) {
                    <div class="col">
                        <p class="mt-0 mb-2 text-start">
                            @if (!sponsorshipService.rewardWithCash) {
                                <span class="fw-bold h5">Total récompense :</span><br>
                                @if (consumer) {<span class="h5">{{consumer.balance}} € de bons d’achat</span>}
                            }
                        </p>
                    </div>
                } @else {

                    <div [@inOutAnimation]="true" class="col px-5">
                        <div class="bg-gold py-4 px-5 mx-auto">
                            <div class="row">
                                <div class="col-7">
                                    <p class="my-0 h5">Date :</p>
                                    @if (consumer) {
                                        <p class="my-0 h5">{{consumer.createdAt | date:'shortDate'}}</p>
                                    }
                                </div>
                                <div class="col-5 text-end">
                                    <p class="h5">{{sponsorshipService.getContent().clientName}}</p>
                                </div>
                            </div>
                            <div class="row my-3">
                                <div class="col text-center">
                                    <p class="my-0 h4">Code Parrain :</p>
                                    <p class="my-0 h4 fw-bold">
                                        {{sponsorService.sponsorshipUser?.codeCustomised || sponsorService.sponsorshipUser?.code}}
                                    </p>
                                </div>
                                <div class="row">
                                    <div (click)="showModal = !showModal" (keydown)="showModal = !showModal"
                                         class="col text-center">
                                        <qrcode [qrdata]="qrdata" errorCorrectionLevel="L" size="200" />
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4 mx-auto">
                                <div class="col mx-auto btnMaxWidth">
                                    <button (cdkCopyToClipboardCopied)="copyContentDesktop='Code parrain copié !';
                                                               trackingService.trackEvent('share', 'copy code','', '',
                                                               trackingServiceSponsorship.getClientTrackerId())"
                                            [cdkCopyToClipboard]="urlToShare"
                                            class="btn btn-gold mx-auto my-1 pt-2 d-flex justify-content-around
                                               align-items-center w-100">
                                        <em aria-hidden="true" class="fas fa-copy fa-2x mx-1"></em>
                                        <span>{{copyContentDesktop}}</span>
                                    </button>
                                    <button (click)="inviteByMail()"
                                            class="btn btn-gold mx-auto my-1 pt-2 d-flex justify-content-around
                                               align-items-center w-100">
                                        <em aria-hidden="true" class="fas fa-paper-plane fa-2x mx-1"></em>
                                        <span>Envoyer par email</span>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                }
            </div>

        </div>

    </div>
    <!--  mobile & desktop ----------------------------------------------------------------------------------------- -->
    @if (!isCollapsed) {
        <button (click)="isCollapsed = !isCollapsed"
                class="btn btn-gold mx-auto mb-n4 px-3 pt-2 d-flex align-items-center btnBorder">Fermer
        </button>
    } @else {
        <button (click)="isCollapsed = !isCollapsed"
                aria-controls="collapseEvent"
                class="btn btn-gold mx-auto mb-n4 px-3 pt-2 d-flex align-items-center btnBorder"
                data-cy="more">Plus de Fonctionnalités
        </button>
    }
</div>

<!-- QRCode Modal -------------------------------------------------------------------------------------------------- -->
<div class="modal {{showModal ? 'd-block' : 'd-none'}}" id="myModal">
    <span (click)="showModal = !showModal" (keydown)="showModal = !showModal" class="close">&times;</span>
    <div class="row g-0 m-0">
        <div class="col-12 col-md-6 g-0 mx-auto text-center">
            <qrcode [qrdata]="qrdata"
                     errorCorrectionLevel="M"
                     size="250" />
        </div>
    </div>
    <div id="caption">
        <a class="text-white" href="{{urlToShare}}" rel="noopener" target="_blank">{{urlToShare}}</a>
    </div>
</div>

<!-- end QRCode Modal ---------------------------------------------------------------------------------------------- -->
